<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="产品名称"
          v-model="searchParam.productName"
          placeholder="请输入产品名称"
        />
        <community-select
          spaceTypes="10,30"
          specifyCollectionTypes=""
          label="选择园区"
          v-model="searchParam.spaceId"
        />
        <v-input
          label="规则名称"
          v-model="searchParam.ruleName"
          placeholder="请输入规则名称"
        />
        <v-select
          clearable
          :options="ruleStatus"
          v-model="searchParam.status"
          label="规则状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
        <v-button
          text="禁用"
          v-if="scope.row.status == 1"
          type="text"
          @click="disable(scope.row, 0)"
        />
        <v-button text="开启" v-else type="text" @click="open(scope.row, 1)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getRuleListUrl,
  addRuleUrl,
  addWarningUrl,
  deleteRuleUrl,
  updateRuleStatusUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
import { ruleStatus } from "./map.js";
export default {
  name: "iotRule",
  data() {
    return {
      ruleStatus,
      searchParam: {
        productName: null,
        spaceId: null,
        ruleName: null,
        status: null,
      },
      tableUrl: getRuleListUrl,
      headers: [
        {
          prop: "spaceName",
          label: "园区名称",
        },
        {
          prop: "ruleName",
          label: "规则名称",
        },
        {
          prop: "productNames",
          label: "关联产品",
        },
        {
          prop: "warningContents",
          label: "关联告警内容",
        },
      ],
    };
  },
  methods: {
    edit(item) {
      // 编辑
      this.$router.push({
        name: "iotRuleForm",
        query: {
          id: item.id,
        },
      });
    },
    remove(item) {
      //删除
      MessageBox.confirm("是否删除改条规则", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${deleteRuleUrl({ ruleId: item.id })}`)
            .then((res) => {
              if (res.code == 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    disable(item, status) {
      // 禁用
      MessageBox.confirm("是否确认禁用该条规则", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
            status: status,
          };
          this.$axios
            .post(`${updateRuleStatusUrl}`, params, {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              if (res.code == 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    open(item, status) {
      // 开启
      MessageBox.confirm("是否确认开启该条规则", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
            status: status,
          };
          this.$axios
            .post(`${updateRuleStatusUrl}`, params, {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              if (res.code == 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    add() {
      // 新增
      this.$router.push({
        name: "iotRuleForm",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  height: 100%;
  box-sizing: border-box;
}
</style>
